import React from 'react'
import Text from '@embracesbs/component-text'
import { Subscription } from '../../Interfaces/Types'

interface SubscriptionExtendedDetailsProbs {
  subscription?: Subscription
}

const SubscriptionExtendedDetails: React.FC<SubscriptionExtendedDetailsProbs> = ({ subscription }) => {
  if (!subscription) {
    return <></>
  }

  return (
    <> 
      <Text textStyle='h3'>{subscription.customerName}</Text>
      <br></br>   
      <Text textStyle='h6'>Subscription Properties</Text>
      <br></br>           
      <Text textColor='grey' textStyle='description'>
        CreatedBy:
      </Text>
      <Text textStyle='body'>{subscription.createdBy}</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        CreationTime:
      </Text>
      <Text textStyle='body'>{subscription.creationTime}</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        LastModifiedBy:
      </Text>
      <Text textStyle='body'>{subscription.modifiedBy}</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        PipelineUrl:
      </Text>
      <Text textStyle='body'>
        <a href={`https://dev.azure.com/embrace/Embrace%20Suite/_build?definitionScope=${subscription.customerName}`} target="_blank" rel="noopener noreferrer">
          Azure Devops Link
        </a>
      </Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        InternalId:
      </Text>
      <Text textStyle='body'>{subscription.id}</Text>

      
    </>
  )
}

export default SubscriptionExtendedDetails
